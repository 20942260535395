/**
 * File add-to-cart-ajax.js.
 *
 * Add products to cart via ajax.
 *
 * Author: Diego Versiani
 * Contact: https://diegoversiani.me
 *
 * DEPENDS ON:
 * - jQuery // Interact with wp_ajax and dispatch jQuery events expected by WooCommerce and third party plugins
 * - shared/which-animation-event.js // Detect end of animation
 * - number-input.js // convert number fields into custom component
 */

(function( $ ){

    'use strict';
  
    // Run initialize on pageload
    window.addEventListener( 'DOMContentLoaded', init );
    window.addEventListener( 'load', initJqueryEventHandlers );
    window.addEventListener( 'load', initQuantityFields );
    window.addEventListener( 'click', handleCapturedClick, true );
  
    
  
    var productAddToCartButtonSelector = '.single_add_to_cart_button',
        removeCartItemButtonSelector = '.mini_cart_item .remove',
        cartCountSelector = '.cart-toggle',
        cartWrapperSelector = '.widget_shopping_cart',
        checkoutButtonSelector = '.widget_shopping_cart .checkout',
        messageContainerSelector = '.message-container',
        productAddToCartButton,
        cartCount,
        messageContainer,
        buttonLoadingClass = 'loading',
        buttonAddedToCartClass = 'added',
        cartCountAnimationClass = 'wobble',
        animationEndEvent;
  
  
    /**
     * Initialize component and set related handlers
     */
    function init( e ) {
      // bail early if woocommerce params not present
      if ( !wc_add_to_cart_params ) {
        return;
      }
  
      resetVariables();
  
      // Try init quantity fields before fragments updated
      initQuantityFields( e );
    };
  
  
  
    /* SEE shared/which-animation-event.js */
    function whichAnimationEvent() {
      if ( window.whichAnimationEvent ) {
        return window.whichAnimationEvent();
      }
  
      return 'animationend';
    };
  
  
  
    /**
     * Initialize event handlers dispatched via jQuery
     */
    function initJqueryEventHandlers() {
      $( document.body ).on( 'wc_fragments_loaded', initQuantityFields );
      $( document.body ).on( 'wc_fragments_refreshed', initQuantityFields );
      $( document.body ).on( 'adding_to_cart', processBeforeAddToCart );
      $( document.body ).on( 'added_to_cart', processAfterAddToCart );
      $( document.body ).on( 'removing_from_cart', processBeforeRemoveFromCart );
      $( document.body ).on( 'removed_from_cart', processAfterRemoveFromCart );
    };
  
  
  
    /**
     * Reset global variables
     */
    function resetVariables() {
      animationEndEvent = whichAnimationEvent();
      productAddToCartButton = document.querySelector( productAddToCartButtonSelector );
      cartCount = document.querySelector( cartCountSelector );
      messageContainer = document.querySelector( messageContainerSelector );
    };
  
  
  
    //
    // ADD TO CART FUNCTIONS
    //
  
    /**
     * Add simple product to cart
     */
    function addToCart_simple( form ) {
      var product_id = productAddToCartButton.value,
          quantity = form.querySelector( 'input[name=quantity]' ).value;
  
      var post_data = {
        action: 'woocommerce_add_to_cart_simple_tattoobon',
        product_id: product_id,
        quantity: quantity
      };
  
      // Get serialized form data and add to post_data
      var serializedForm = $( form ).serializeArray();
      for (var i = 0; i < serializedForm.length; i++) {
        post_data[ serializedForm[i].name ] = serializedForm[i].value;
      }
  
      // Trigger adding_to_cart event
      // Pass button as a jQuery object
      $( document.body ).trigger( 'adding_to_cart', [ $(productAddToCartButton), post_data ] );
  
      // Sent post
      $.post( wc_add_to_cart_params.ajax_url, post_data, processSuccessfulAddToCartRequest );
    };
  
  
  
    /**
     * Add variable product to cart
     */
    function addToCart_variable( form ) {
      var product_id = form.querySelector( 'input[name=product_id]' ).value,
          quantity = form.querySelector( 'input[name=quantity]' ).value,
          var_id = form.querySelector( 'input[name=variation_id]' ).value,
          variations = form.querySelectorAll( 'select[name^=attribute]' ),
          variation_data = getVariationData( variations );
  
      var post_data = {
        action: 'woocommerce_add_to_cart_variable_tattoobon',
        product_id: product_id,
        quantity: quantity,
        variation_id: var_id,
        variation: variation_data
      };
  
      // Get serialized form data and add to post_data
      var serializedForm = $( form ).serializeArray();
      for (var i = 0; i < serializedForm.length; i++) {
        post_data[ serializedForm[i].name ] = serializedForm[i].value;
      }
  
      // Trigger adding_to_cart event
      // Pass button as a jQuery object
      $( document.body ).trigger( 'adding_to_cart', [ $(productAddToCartButton), post_data ] );
  
      // Sent post
      $.post( wc_add_to_cart_params.ajax_url, post_data, processSuccessfulAddToCartRequest );
    };
  
  
  
    /**
     * Validate and retrieve product variation data
     */
    function getVariationData( variations ) {
      var variation_data = {},
          valid = true;
  
      // Iterate variations and validate selected values
      for ( var i = 0; i < variations.length; i++ ) {
        var attributeName = variations[i].getAttribute( 'name' ),
            attributevalue = variations[i].value;
  
        if ( attributevalue.length === 0 ) {
          valid = false; // Attribute has no value set
        }
        else {
          variation_data[ attributeName ] = attributevalue;
        }
      }
  
      if ( !valid ) { return false; }
  
      return variation_data;
    };
  
  
  
    /**
     * Handle a successful request for adding product to cart
     */
    function processSuccessfulAddToCartRequest( response ) {
      // Display messages
      if ( response.message ) {
        displayMessage( response.message );
        
        // Set button classes
        if ( productAddToCartButton ) {
          releaseAddToCartButton( productAddToCartButton );
        }
      }
  
      // if ( !response.error && !response.message ) {
        // Call fragments refresh for add_to_cart
        refreshFragmentsAddToCart();
      // }
    };
  
  
  
    /**
     * Process before adding product to cart
     */
    function processBeforeAddToCart( event, $button, $data ) {
      if ( $button ) {
        $button[0].classList.remove( buttonAddedToCartClass );
        blockAddToCartButton( $button[0] );
      }
  
      // Block checkout button
      // Button is released when fragment is updated
      blockCheckoutButton();
  
      // Clear messages
      clearMessage();
    };
  
  
  
    /**
     * Process after successful adding product to cart
     */
    function processAfterAddToCart( event, $cart_hash, $data, $button ){
  
      // Request animation frame to allow fragments
      // to be replaced before proceding with animation
      window.requestAnimationFrame( function() {
        resetVariables();
  
        // Set cart animationend event handler
        cartCount.addEventListener( animationEndEvent, function finishCartAnimation() {
          // Open cart preview
          if ( window.cartPreview ) {
            window.cartPreview.open();
          }
  
          // Remove animation class and event listener
          cartCount.classList.remove( cartCountAnimationClass );
          cartCount.removeEventListener( animationEndEvent, finishCartAnimation );
        } );
  
        // Play cart icon animation
        cartCount.classList.add( cartCountAnimationClass );
  
        if ( $button ) {
          // Set button classes
          releaseAddToCartButton( $button[0] );
          $button[0].classList.add( buttonAddedToCartClass );
        }
      });
    };
  
  
  
    //
    // REMOVE CART ITEM FUNCTIONS
    //
  
    /**
     * Remove item from cart
     */
    function removeCartItem( cart_item_key ) {
      var post_data = {
        action: 'woocommerce_remove_cart_item_tattoobon',
        cart_item_key: cart_item_key
      };
  
      // Trigger removing_from_cart event
      // Pass button as a jQuery object
      $( document.body ).trigger( 'removing_from_cart', [ post_data ] );
  
      // Sent post
      $.post( wc_add_to_cart_params.ajax_url, post_data, processSuccessfulRemoveCartItemRequest );
    };
  
  
  
    /**
     * Handle a successful request for remove cart item
     */
    function processSuccessfulRemoveCartItemRequest( response ) {
      // Display messages
      if ( response.message ) {
        displayMessage( response.message );
      }
  
      // Dispatch `removed_from_cart` event if no error was thrown
      if ( !response.error ) {
        // Trigger fragments refresh
        refreshFragments();
  
        // Trigger event so themes can refresh other areas
        $( document.body ).trigger( 'removed_from_cart', [ ] );
      }
      // Process error recovery
      else {
        var cartItem = document.querySelector( '.mini_cart_item-' + response.cart_item_key );
        if ( cartItem ) {
          // Remove processing feedback from item
          cartItem.classList.remove( 'updating' );
  
          // Re-enable remove button after error
          var removeButton = cartItem.querySelector( '.remove' );
          if ( removeButton ) {
            removeButton.classList.remove( 'disabled' );
          }
        }
      }
    };
  
  
  
    /**
     * Process before removing cart item
     */
    function processBeforeRemoveFromCart( event, $data ) {
      var cartItem = document.querySelector( '.mini_cart_item-' + $data.cart_item_key );
      if ( cartItem ) {
        // Disable remove button to prevent duplicate click
        var removeButton = cartItem.querySelector( '.remove' );
        removeButton.classList.add( 'disabled' );
  
        // Block checkout button
        // Button is released when fragment is updated
        blockCheckoutButton();
  
        // Feedback while processing
        cartItem.classList.add( 'updating' );
      }
    };
  
  
  
    /**
     * Process after successful adding product to cart
     */
    function processAfterRemoveFromCart( event, $cart_item_key ){
      var cartItem = document.querySelector( '.mini_cart_item-' + $cart_item_key );
      if ( cartItem ) {
        // Animate removing item
        cartItem.classList.add( 'slide-out-right' );
        cartItem.addEventListener( animationEndEvent, function finishRemovingAnimation() {
          cartItem.style.display = 'none';
          cartItem.removeEventListener( animationEndEvent, finishRemovingAnimation );
  
          // Trigger fragments refresh
          refreshFragments();
        } );
      }
    };
  
  
  
    //
    // SET CART ITEM QUANTITY FUNCTIONS
    //
  
    /**
     * Set cart item quantity
     */
    function setCartItemQuantity( e ) {
      var inputField = e.target;
      var cart_item_key = inputField.getAttribute( 'data-cart_item_key' );
      
      // Get sanitized quantity
      var maxQuantity = inputField.getAttribute( 'max' ) ? parseInt( inputField.getAttribute( 'max' ) ) : null;
      var quantity = parseInt( inputField.value );
      quantity = ( maxQuantity && quantity >= maxQuantity ) ? maxQuantity : quantity;
  
      // Update inputField with sanitized quantity
      inputField.value = quantity;
  
      var post_data = {
        action: 'woocommerce_set_cart_item_quantity_tattoobon',
        cart_item_key: cart_item_key,
        quantity: quantity
      };
  
      // Set event listeners on demand
      $( inputField ).on( 'updating_quantity', processBeforeSetQuantity );
      $( inputField ).on( 'updated_quantity', processAfterSetQuantity );
  
      // Trigger removing_from_cart event
      // Pass button as a jQuery object
      $( inputField ).trigger( 'updating_quantity', [ post_data ] );
  
      // Sent post
      $.post( wc_add_to_cart_params.ajax_url, post_data, processSuccessfulSetQuantityRequest );
    };
  
  
  
    /**
     * Handle a successful request for set cart item quantity
     */
    function processSuccessfulSetQuantityRequest( response ) {
      // Display messages
      if ( response.message ) {
        displayMessage( response.message );
      }
  
      var inputField = document.querySelector( '.mini_cart_item-' + response.cart_item_key + ' .quantity input[type="number"]' );
      if ( inputField ) {
        // Remove updating_quantity event listener
        $( inputField ).off( 'updating_quantity', processBeforeSetQuantity );
  
        // Dispatch `updated_quantity` event if no error was thrown
        if ( !response.error ) {
          // Trigger event so themes can refresh other areas
          // Pass button as a jQuery object
          $( inputField ).trigger( 'updated_quantity', [ response ] );
        }
      }
    };
  
  
  
    /**
     * Process before removing cart item
     */
    function processBeforeSetQuantity( event, $data ) {
      var cartItem = document.querySelector( '.mini_cart_item-' + $data.cart_item_key );
      if ( cartItem ) {
        // Feedback while processing
        cartItem.classList.add( 'updating' );
        
        // Block checkout button
        // Button is released when fragment is updated
        blockCheckoutButton();
        
        // Remove event listener added on demand
        var inputField = cartItem.querySelector( '.quantity input[type="number"]' );
        if ( inputField ) {
          // Remove updating_quantity event listener
          $( inputField ).off( 'updating_quantity', processBeforeSetQuantity );
        }
      }
    };
  
  
  
    /**
     * Process after successful adding product to cart
     */
    function processAfterSetQuantity( event, $data ) {
      // Trigger fragments refresh first
      refreshFragments();
  
      var cartItem = document.querySelector( '.mini_cart_item-' + $data.cart_item_key );
      if ( cartItem ) {
        // Remove event listener added on demand
        var inputField = cartItem.querySelector( '.quantity input[type="number"]' );
        if ( inputField ) {
          // Remove updated_quantity event listener
          $( inputField ).off( 'updated_quantity', processAfterSetQuantity );
        }
      }
    };
  
  
  
    //
    // UTILITY FUNCTIONS
    //
  
    /**
     * Display ajax message.
     */
    function displayMessage( message ) {
      if ( messageContainer ) {
        messageContainer.innerHTML = message;
        // Animate message addition
        messageContainer.classList.add( 'fade-in' );
        messageContainer.addEventListener( animationEndEvent, function finishMessageAnimation() {
          messageContainer.classList.remove( 'fade-in' );
          messageContainer.removeEventListener( animationEndEvent, finishMessageAnimation );
        } );
      }
    };
  
  
  
    /**
     * Clear ajax message.
     */
    function clearMessage( message ) {
      if ( messageContainer ) {
        messageContainer.classList.add( 'fade-out' );
        messageContainer.addEventListener( animationEndEvent, function finishMessageAnimation() {
          messageContainer.innerHTML = '';
          messageContainer.classList.remove( 'fade-out' );
          messageContainer.removeEventListener( animationEndEvent, finishMessageAnimation );
        } );
      }
    };
  
  
  
    /**
     * Replace fragments.
     */
    function replaceFragments( fragments ) {
      window.requestAnimationFrame( function() {
        $.each( fragments, function( key, value ) {
          $( key ).replaceWith( value );
        });
  
        $( document.body ).trigger( 'wc_fragments_loaded' );
      } );
    };
  
  
  
    /**
     * Refresh fragments.
     */
    function refreshFragments() {
      var post_data = {
        action: 'woocommerce_refresh_fragments_tattoobon'
      };
  
      // Sent post
      $.post( wc_add_to_cart_params.ajax_url, post_data, processSuccessfulRefreshFragmentsRequest );
    };
  
  
  
    /**
     * Refresh fragments for add_to_cart.
     */
    function refreshFragmentsAddToCart() {
      var post_data = {
        action: 'woocommerce_refresh_fragments_tattoobon'
      };
  
      // Sent post
      $.post( wc_add_to_cart_params.ajax_url, post_data, processSuccessfulRefreshFragmentsAddToCartRequest );
    };
  
  
  
    /**
     * Process successful refresh fragments request.
     */
    function processSuccessfulRefreshFragmentsRequest( response ) {
      // Replace fragments
      if ( response.fragments ) {
        replaceFragments( response.fragments );
      }
  
      // Display messages
      if ( response.message ) {
        displayMessage( response.message );
      }
    };
  
  
  
    /**
     * Process successful refresh fragments for add_to_cart request.
     */
    function processSuccessfulRefreshFragmentsAddToCartRequest( response ) {
      // Process refreshFragments response
      processSuccessfulRefreshFragmentsRequest( response );
      
      // Dispatch `added_to_cart` event if no error was thrown
      if ( !response.error && !response.message ) {
        // Trigger event so themes can refresh other areas
        // Pass button as a jQuery object
        $( document.body ).trigger( 'added_to_cart', [ response.fragments, response.cart_hash, $(productAddToCartButton) ] );
      }
  
      // Release add to cart button
      productAddToCartButton.disabled = false;
      
      // Release checkout button after try add to cart
      releaseCheckoutButton();
    };
  
  
  
    /**
     * Initialize number input fields in cart preview.
     */
    function initQuantityFields( e ) {
      // Init catalog view selector component and set local variables
      if ( inputNumbers ) {
        inputNumbers.init({
          container: cartWrapperSelector,
          minusButtonTemplate: '<button type="button" class="number-spin-button minus"><i class="i-minus"></i></button>',
          plusButtonTemplate: '<button type="button" class="number-spin-button plus"><i class="i-plus"></i></button>',
          onChange: setCartItemQuantity,
        });
      }
    };
  
  
  
    /**
     * Block checkout button
     */
    function blockCheckoutButton() {
      var checkoutButton = document.querySelector( checkoutButtonSelector );
      if ( checkoutButton ) {
        checkoutButton.addEventListener( 'click', disableCheckoutButton );
        checkoutButton.classList.add( 'disabled' );
      }
    };
  
    /**
     * Release checkout button
     */
    function releaseCheckoutButton() {
      var checkoutButton = document.querySelector( checkoutButtonSelector );
      if ( checkoutButton ) {
        checkoutButton.removeEventListener( 'click', disableCheckoutButton );
        checkoutButton.classList.remove( 'disabled' );
      }
    };
  
  
  
    /**
     * Block add-to-cart button
     * @param  Element button Button to block.
     */
    function blockAddToCartButton( button ) {
      if ( button ) {
        button.classList.add( buttonLoadingClass );
        button.disabled = true;
      }
    };
  
    /**
     * Release button after finishing ajax call.
     * @param  Element button Button to release.
     */
    function releaseAddToCartButton( button ) {
      if ( button ) {
        button.classList.remove( buttonLoadingClass );
        button.disabled = false;
      }
    };
  
  
  
    /**
     * Disable checkout button click
     */
    function disableCheckoutButton( e ) {
      e.preventDefault();
    };
  
  
  
    //
    // CLICK HANDLER
    //
  
    /**
     * Handle captured click for cartButton
     */
    function handleCapturedClick( e ) {
      var node = e.target;
  
      // climb up the dom three
      while ( node != document.body ) {
  
        // handle add-to-cart click
        if ( node.matches( productAddToCartButtonSelector ) ) {
          
          // Check if button is enabled
          if ( !productAddToCartButton.hasAttribute( 'disabled' )
                && !productAddToCartButton.classList.contains( 'disabled' ) ) {
            // Get product element
            var productElement = productAddToCartButton.closest( '.product' );
            
            // Add to cart with appropriate script
            if ( productElement.classList.contains( 'product-type-simple' ) ) {
              e.preventDefault();
              addToCart_simple( productAddToCartButton.closest( 'form' ) );
            }
            else if ( productElement.classList.contains( 'product-type-variable' ) ) {
              e.preventDefault();
              addToCart_variable( productAddToCartButton.closest( 'form' ) );
            }
            
            return;
          }
  
          // Exit from handle function
          return;
        }
        // handle remove-cart-item click
        else if ( node.matches( removeCartItemButtonSelector ) ) {
          // Get cart_item_key
          var cart_item_key = node.getAttribute( 'data-cart_item_key' );
  
          if ( !node.classList.contains( 'disabled' ) ) {
            e.preventDefault();
  
            // Remove cart item
            removeCartItem( cart_item_key );
          }
  
          // Exit from handle function
          return;
        }
  
        node = node.parentNode;
      }
    };
  
  })( jQuery );
  